<template>
  <v-form @submit.prevent="$emit('save', client)" autocomplete="off">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <!--COMPANY-->
          <v-col cols="12" class="mt-4">
            <div class="text-h6">{{ $t('company') }}</div>
            <v-divider />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="client.importance"
              :items="importanceItems"
              :error-messages="errors['importance']"
              :label="formMixin_getRequiredFieldLabel($t('importance'))"
              item-text="label"
              item-value="value"
              @input="formMixin_clearErrors('importance')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.name"
              :error-messages="errors['name']"
              :label="formMixin_getRequiredFieldLabel($t('name'))"
              @input="formMixin_clearErrors('name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.code"
              :error-messages="errors['code']"
              :label="$t('code')"
              @input="formMixin_clearErrors('code')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.vat_code"
              :error-messages="errors['vat_code']"
              :label="$t('vat_code')"
              @input="formMixin_clearErrors('vat_code')"
            />
          </v-col>

          <!--ADDRESS-->
          <v-col cols="12" class="mt-4">
            <div class="text-h6 title">{{ $t('address') }}</div>
            <v-divider />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.street"
              :error-messages="errors['street']"
              :label="$t('street')"
              @input="formMixin_clearErrors('street')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.city"
              :error-messages="errors['city']"
              :label="$t('city')"
              @input="formMixin_clearErrors('city')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.post_code"
              :error-messages="errors['post_code']"
              :label="$t('post_code')"
              @input="formMixin_clearErrors('post_code')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="client.country"
              :error-messages="errors['country']"
              :label="formMixin_getRequiredFieldLabel($t('country'))"
              :items="countries || []"
              item-text="title"
              item-value="id"
              @input="formMixin_clearErrors('country')"
            />
          </v-col>

          <!--ADDITIONAL INFO-->
          <v-col cols="12" class="mt-4">
            <div class="text-h6">{{ $t('additional_info') }}</div>
            <v-divider />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.product_id_1_coef"
              :error-messages="errors['product_id_1_coef']"
              :label="formMixin_getRequiredFieldLabel($t('product_id_1_coef'))"
              type="number"
              min="0.01"
              max="99"
              step="0.01"
              @input="formMixin_clearErrors('product_id_1_coef')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.product_id_2_coef"
              :error-messages="errors['product_id_2_coef']"
              :label="formMixin_getRequiredFieldLabel($t('product_id_2_coef'))"
              type="number"
              min="0.01"
              max="99"
              step="0.01"
              @input="formMixin_clearErrors('product_id_2_coef')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.product_id_3_coef"
              :error-messages="errors['product_id_3_coef']"
              :label="formMixin_getRequiredFieldLabel($t('product_id_3_coef'))"
              type="number"
              min="0.01"
              max="99"
              step="0.01"
              @input="formMixin_clearErrors('product_id_3_coef')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.assembly_coef"
              :error-messages="errors['assembly_coef']"
              :label="formMixin_getRequiredFieldLabel($t('assembly_coef'))"
              type="number"
              min="0.01"
              max="99"
              step="0.01"
              @input="formMixin_clearErrors('assembly_coef')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.installation_coef"
              :error-messages="errors['installation_coef']"
              :label="formMixin_getRequiredFieldLabel($t('installation_coef'))"
              type="number"
              min="0.01"
              max="99"
              step="0.01"
              @input="formMixin_clearErrors('installation_coef')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="client.default_currency"
              :items="currencyItems"
              :error-messages="errors['default_currency']"
              :label="formMixin_getRequiredFieldLabel($t('default_currency'))"
              item-text="label"
              item-value="value"
              @input="formMixin_clearErrors('default_currency')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="client.manufacturing_term_in_days"
              :error-messages="errors['manufacturing_term_in_days']"
              :label="formMixin_getRequiredFieldLabel($t('manufacturing_term_in_days'))"
              type="number"
              min="0"
              step="1"
              @input="formMixin_clearErrors('manufacturing_term_in_days')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="client.is_active"
              :label="$t('is_active')"
              :error-messages="errors.is_active"
              @change="formMixin_clearErrors('is_active')"
            />
          </v-col>

          <v-col cols="12" class="mt-4">
            <div class="text-h6">{{ $t('contacts') }}</div>
            <v-divider />
          </v-col>

          <!--CONTACTS-->
          <v-col cols="12">
            <v-row v-for="(contact, index) in client.contacts" :key="`contact.${index}`">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="contact.first_name"
                  :error-messages="errors[`contacts.${index}.first_name`]"
                  :label="formMixin_getRequiredFieldLabel($t('first_name'))"
                  @input="formMixin_clearErrors(`contacts.${index}.first_name`)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="contact.last_name"
                  :error-messages="errors[`contacts.${index}.last_name`]"
                  :label="formMixin_getRequiredFieldLabel($t('last_name'))"
                  @input="formMixin_clearErrors(`contacts.${index}.last_name`)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="contact.email"
                  :error-messages="errors[`contacts.${index}.email`]"
                  :label="formMixin_getRequiredFieldLabel($t('email'))"
                  @input="formMixin_clearErrors(`contacts.${index}.email`)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="contact.pivot.position"
                  :error-messages="errors[`contacts.${index}.pivot.position`]"
                  :label="formMixin_getRequiredFieldLabel($t('position'))"
                  @input="formMixin_clearErrors(`contacts.${index}.pivot.position`)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="contact.pivot.contact_additional_email"
                  :error-messages="errors[`contacts.${index}.pivot.contact_additional_email`]"
                  :label="$t('contact_additional_email')"
                  @input="formMixin_clearErrors(`contacts.${index}.pivot.contact_additional_email`)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="contact.pivot.force_spec_coefs"
                  :error-messages="errors[`contacts.${index}.pivot.force_spec_coefs`]"
                  :label="$t('force_spec_coefs')"
                  @input="formMixin_clearErrors(`contacts.${index}.pivot.force_spec_coefs`)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="contact.pivot.product_id_1_coef"
                  :error-messages="errors[`contacts.${index}.pivot.product_id_1_coef`]"
                  :label="formMixin_getRequiredFieldLabel($t('product_id_1_coef'))"
                  type="number"
                  min="0.01"
                  max="99"
                  step="0.01"
                  @input="formMixin_clearErrors(`contacts.${index}.pivot.product_id_1_coef`)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="contact.pivot.product_id_2_coef"
                  :error-messages="errors[`contacts.${index}.pivot.product_id_2_coef`]"
                  :label="formMixin_getRequiredFieldLabel($t('product_id_2_coef'))"
                  type="number"
                  min="0.01"
                  max="99"
                  step="0.01"
                  @input="formMixin_clearErrors(`contacts.${index}.pivot.product_id_2_coef`)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="contact.pivot.product_id_3_coef"
                  :error-messages="errors[`contacts.${index}.pivot.product_id_3_coef`]"
                  :label="formMixin_getRequiredFieldLabel($t('product_id_3_coef'))"
                  type="number"
                  min="0.01"
                  max="99"
                  step="0.01"
                  @input="formMixin_clearErrors(`contacts.${index}.pivot.product_id_3_coef`)"
                />
              </v-col>

              <v-col cols="12">
                <v-btn color="error lighten-2" class="mx-3" @click="removeLivingAddress(index)">
                  <v-icon left>mdi-delete</v-icon>
                  {{ $t('remove_contact') }}
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-divider v-if="index < client.contacts.length - 1" class="mt-4" />
              </v-col>
            </v-row>

            <v-btn color="primary" class="my-4 mx-3" @click="addLivingAddress">
              <v-icon left>mdi-plus</v-icon>
              {{ $t('add_contact') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!client.id" class="text-subtitle-2 ml-3"> * {{ $t('must_be_filled') }}</span>

        <v-spacer />

        <v-btn text color="primary" @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text type="submit">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import { getDefaultClientContacts } from '@/store/modules/clients-module';
import { mapState, mapActions, mapGetters } from 'vuex';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';

export default {
  name: 'ClientForm',

  mixins: [formMixin],

  props: {
    client: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('countries', ['countries']),
    ...mapGetters('clients', ['importanceItems']),

    currencyItems() {
      return [
        {
          label: this.$t('currency.eur'),
          value: 1,
        },
        {
          label: this.$t('currency.gbp'),
          value: 2,
        },
        {
          label: this.$t('currency.rub'),
          value: 3,
        },
        {
          label: this.$t('currency.usd'),
          value: 4,
        },
      ];
    },

    formTitle() {
      return this.$t(this.client.id ? 'edit_client' : 'new_client');
    },
  },

  created() {
    if (!this.countries) {
      this.fetchCountries();
    }
  },

  methods: {
    ...mapActions('countries', ['fetchCountries']),

    addLivingAddress() {
      this.client.contacts.push(getDefaultClientContacts());
    },

    removeLivingAddress(index) {
      openConfirmDialog({
        title: this.$t('confirm_contact_delete'),
      }).then((confirmed) => {
        if (!confirmed) {
          return;
        }
        this.client.contacts.splice(index, 1);
        eventBus.$emit(OPEN_SNACKBAR, this.$t('contact_deleted'));
      });
    },
  },
};
</script>

<style scoped></style>
